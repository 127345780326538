<template>
  <div class="wrapper">
    <div class="wrapper__container">
      <jublia-header />
      <div class="container">
        <jublia-nav-step :trad="'treatment.treatmentProgress.title'" />
        
        <jublia-h3 class="text DINBold  mx-0 mb-4">{{
          $t('treatment.treatmentProgress.subTitle')
        }}</jublia-h3>
       
        <jublia-p class="mb-2 text">{{
          $t('treatment.treatmentProgress.text')
        }}</jublia-p>
        <jublia-p
          v-html="$t('treatment.treatmentProgress.text2')"
          class="mb-4 text"
        />
        <jublia-p  class="mb-2 text" v-html="$t('treatment.treatmentProgress.secure')" />
        <!-- FORM TEL -->
        <form @submit.prevent="goToNextStep" v-if="displayForm">
          <input
            v-mask="['###-###-####']"
            v-model.trim=" $v.phoneInput.$model"
            type="tel"
            name="phone"
             class="w-full text-center border border-jublia-blue rounded-lg p-2 mt-8 text-base w-64"
            placeholder="000-000-0000"
          />
          <jublia-p  class="my-2 mt-2 text" tiny>{{ $t('treatment.reminder.fees') }}</jublia-p>
        </form>
        <!-- ERROR PHONE NUMBER -->
        
        <div
          v-if="
            (submited &&
              (!$v.phoneInput.minLength || !$v.phoneInput.maxLength)) ||
              (submited && error)
          "
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text"> {{ $t('treatment.reminder.errorPhone') }}</p>
        </div>
       
         <div  class="w-full flex justify-between mb-4 text  text-black">
          <jublia-p class="cursor-pointer flex  textline "  style="font-size: .9rem;">
              <!--{{ $t('common.call') }}-->
            </jublia-p>

          <div @click="NOBeforeNextStep" class="flex ">
            <span v-if="noSelected" class="puce selected  border border-black border-4" style=" border:3px solde #006699 !important;" >✓</span>
            <span v-else class="puce  border border-black border-4"  style=" border:3px solde #006699 !important;"/>
            <jublia-p class="cursor-pointer DINBold text-black textline" >
              {{ $t('common.noThanks') }}
            </jublia-p>
          </div>
         </div>
        <jublia-popup-end v-if="!treatmentProgress">
          <jublia-p  v-html="$t('popup.text2')" class="w-full text text2" />
        </jublia-popup-end>
        
       
        <div class="w-full md:mx-auto md:w-4/5 lg:w-2/3">
          <jublia-button
            @click.native="goToNextStep"
            bgpurple
            class="w-full flex justify-between items-center"
          >
            <div class="text-xl font-bold mr-2">
              {{ $t('common.next') }}
            </div>
            <img
              src="@/assets/icons/next.png"
              alt="next button"
              width="10"
              height="10"
            />
          </jublia-button>
        </div>
        <jublia-breadcrumb :currentStep="3" :totalStep="4" />
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapState, mapMutations ,mapActions,useStore} from 'vuex'
import {maxLength, minLength} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import axios from 'axios';

import JubliaHeader from '@/components/header/jublia-header'
import JubliaNavStep from '@/components/nav-step/jublia-nav-step'
import JubliaH3 from '@/components/text/jublia-h3'
import JubliaP from '@/components/text/jublia-p'
import JubliaButton from '@/components/button/jublia-button'
import JubliaBreadcrumb from '@/components/breadcrumb/jublia-breadcrumb'
import JubliaPopupEnd from '@/components/popup-end/popup-end'

export default {
  components: {
     'jublia-header':JubliaHeader,
    'jublia-nav-step':JubliaNavStep,
    'jublia-button':JubliaButton,
    'jublia-p':JubliaP,
    'jublia-h3':JubliaH3,
   'jublia-breadcrumb': JubliaBreadcrumb,
    'jublia-popup-end':JubliaPopupEnd,
  },
  directives: {
    mask
  },
   data: () => ({
    error: false,
    user: {},
    phoneInput: null,
    treatmentProgress: true,
    submited: false,
    isLoading: true,
    displayForm: true,
    v:null
  
  }),
   setup () {
     const store= useStore()
       store.dispatch('reminder/init')
    return { $v: useVuelidate() }
  },
 
  computed: {
    ...mapState({
      schemaSelection: (state) => state.treatment.schemaSelection,
     url: (state) => state.login.uri,
     urlT: (state) => state.treatment.nbDays,
      nbMonthsPrescripted: (state) => state.treatment.nbMonthsPrescripted,
      nbDays: (state) => state.treatment.nbDays,
      phone: (state) => state.reminder.phone,
      nbBottles: (state) => state.treatment.nbBottles,
      reminder: (state) => state.reminder.subscribed,
       refill: (state) => state.reminder.refill,
       refillEmail:(state) => state.reminder.refillEmail
    }),
    
  },
  async fetch({ store }) {
    await store.dispatch('reminder/init')
    
    this.init()
  },
  mounted() {
    
   if (this.phone.length>4 ) this.displayForm = false;
   if (this.phone==null) this.displayForm = false;
    if(!!sessionStorage.getItem('user')){
      this.user= JSON.parse(sessionStorage.getItem('user'))||{}
   }
   
   
  },
  created: function () {
    this.v =useVuelidate();
  },
  methods: {
    NOBeforeNextStep({ store }) {
      this.treatmentProgress = false
      this.goToNextStep({ store })
    },
    async goToNextStep({ store }) {
      if (!this.isLoading) {
        return
      }
      this.isLoading = true
      this.submited = true
      this.error = false
     if (this.displayForm) {
       if (this.v.phoneInput.$error) return null
      
        if (this.phoneInput === null) {
          this.error = true
          return null
        }
        this.setPhone(this.phoneInput)
      }
     
      if (this.treatmentProgress || this.reminder) {
        const datas = {
          reminder: this.reminder,
          refill:this.refill,
          refillEmail:sessionStorage.getItem('refillEmail')||this.refillEmail,
          treatmentProgress: this.treatmentProgress,
          nextReminder: this.nbDays,
          nbBottles: this.nbBottles,
          email:this.user.email||'',
          schemaSelection:this.schemaSelection,
          setNbMonthsPrescripted:this.nbMonthsPrescripted,
          phone: this.phone.toString(),
          lang: this.$i18n.getLocale()||'fr',
          uri:sessionStorage.getItem('uri')||null
        }
      
        try {
          const { data } = await axios.post('/user/', datas)
          this.isLoading = true
          
         if(this.url===null)
          this.setUrl(data.uri);

          let url=''
         
          if(data.uri===''){
            url=this.url
         
          } 
          if(data.uri!==''){
            url=data.uri
          
            if(data.user!=null)
             this.$store.dispatch('login/USER_DETAILS',data.user);

           this.setUrl(data.uri)
          }
             if(url=='')
             this.treatmentProgress= false;

          if (this.treatmentProgress) {
            this.$router.push(`/${url}/step-photo`)
           
          }
        } catch (e) {
          this.isLoading = true
          this.error = true
          this.displayForm = true
        }
      }
    },
   
    ...mapMutations({
      setSubscribed: 'reminder/setSubscribed',
      setPhone: 'reminder/setPhone',
      setUrl: 'login/setUrl'
    })
  },
   validations () {
  return {  phoneInput: {
      minLength: minLength(12),
      maxLength: maxLength(12)
    },
  }
  }
 
}
</script>
<style scoped>
/*.puce {
  @apply block rounded-full text-lg border border-jublia-purple flex justify-center items-center cursor-pointer;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  padding-top: 4px;
}*/
.puce {
  @apply block  text-lg  flex justify-center items-center cursor-pointer rounded-sm;
  width: 20px;
border: 2px solid black;
  height: 20px;
  margin-right: 5px;
  padding-top: 4px;
}
.selected {
  @apply bg-jublia-white;
}
.textline {
  line-height:136% !important;
}

.text2 {
  text-align:center !important;
}
</style>
